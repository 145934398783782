import React, { useEffect, useState } from "react";
import omit from "lodash/omit";
import GatsbyImage from "gatsby-image/index";

const LazyImage = ({ ...props }) => {
  const [loadingMethod, setLoadingMethod] = useState("lazy");
  const attributes = { ...props };
  attributes.draggable = false;
  omit(attributes, ["loading", "fadeIn", "draggable"]);

  useEffect(() => {
    setLoadingMethod(() => {
      if (props.loading) {
        return props.loading;
      }

      // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isSafari = navigator.userAgent.toLowerCase().indexOf("safari/") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome || isSafari) {
        // google chrome ios, safari or safari ios
        return "lazy";
      } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
        // is chromium
        return "lazy";
      } else {
        // firefox, pre-chromium edge
        return "eager";
      }
    });
    return () => { };
  }, [props.loading]);

  if (props.fluid || props.fixed) {
    return (
      <GatsbyImage
        {...attributes}
        fadeIn={props.fadeIn ? props.fadeIn : false}
        loading={loadingMethod}
      />
    );
  } else {
    return <img {...attributes} loading={loadingMethod} alt={props.alt} />;
  }
};

export default LazyImage;
